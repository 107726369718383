<template>
  <div class="page">
    <div>
      <!-- 左侧侧边栏 -->
      <sidebarUserInfo></sidebarUserInfo>
    </div>
    <div>
      <btnTopBar
        :topList="topList"
        :moren="moren"
        v-if="topList.length > 0"
      ></btnTopBar>
      <div class="thetopbox">
        <el-form ref="form" :model="queryParams" label-width="100px">
          <el-row style="margin-top: 10px;">
            <el-col :span="4">
              <el-form-item :label="$t('N_I_124')" prop="memberCode">
                <el-input
                  clearable
                  v-model="queryParams.memberCode"
                ></el-input></el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item :label="$t('N_I_242')" prop="memberSettlePeriodId">
                <el-select
                  clearable
                  :placeholder="$t('CK_KS_38')"
                  v-model="queryParams.memberSettlePeriodId"
                >
                  <el-option
                    v-for="item in memberSettlePeriodList"
                    :key="item.pkId"
                    :label="item.settleDate"
                    :value="item.pkId"
                  ></el-option> </el-select></el-form-item
            ></el-col>
            <el-col :span="4">
              <el-form-item :label="$t('w_0353')" prop="level">
                <el-input
                  clearable
                  v-model="queryParams.level"
                ></el-input></el-form-item
            ></el-col>
            <el-col :span="4" style="margin-left: 30px">
              <div class="searchbox">
                <el-button class="searchbtn" @click="getSearch">{{ $t('N_I_183') }}</el-button>
                <el-button @click="reChongzhi">{{ $t('CK_KS_6') }}</el-button>
                <el-button @click="goback">{{$t('N_I_241')}}</el-button>
              </div>
            </el-col>
          </el-row></el-form
        >
      </div>

      <div>
        <div class="tree">
          <el-tree
            :data="data"
            :props="defaultProps"
            @node-click="handleNodeClick"
          >
            <span slot-scope="{ node, data }" class="custom-tree-node">
              <div class="tooltip">
                <el-button size="mini" @click.stop="copyItem(data.splice)"
                  >{{ $t('MY_CK_27') }}</el-button
                >
                <div class="thecontent">{{ data.splice }}</div>
              </div>
            </span>
          </el-tree>
        </div>
      </div>
    </div>
  </div>
</template>
    
  <script>
import { mapGetters } from "vuex";
import btnTopBar from "@/components/btnTopBar.vue";
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import { getTjFramework, getMemberSettlePeriod } from "@/api/archityecture";
export default {
  components: {
    btnTopBar,
    sidebarUserInfo,
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      moren: "recommend",
      topList: [
        {
          name:  this.$t('w_0175'),
          path: "recommend",
        },
        {
          name:  this.$t('w_0176'),
          path: "tjjg2",
        },
        {
          name:  this.$t('w_0177'),
          path: "tjjg3",
        },
        {
          name:  this.$t('w_0178'),
          path: "tjjg4",
        },
      ],
      queryParams: {
        memberSettlePeriodId: "", //期数
        memberCode: "", //会员编号
        level: "", //代数
      },
      data: [],
      defaultProps: {
        label: "memberCode",
        children: "children",
      },
      memberSettlePeriodList: [], //期数
    };
  },
  created() {
    this.queryParams.memberCode = this.userInfo.memberCode;
    this.getSearch();
    this.getAvarerInfo();
  },
  methods: {
    getAvarerInfo() {
      getMemberSettlePeriod().then((res) => {
        this.memberSettlePeriodList = res.data;
        this.memberSettlePeriodList.forEach((ele) => {
          if (ele.isThisDay == 0) {
            this.queryParams.memberSettlePeriodId = ele.pkId;
          }
        });
      });
    },
    //返回
    goback() {
      this.queryParams.memberCode = this.data[0].parentMemberCode;
      this.getSearch();
    },
    //复制
    copyItem(content) {
      if (window.clipboardData) {
        window.clipboardData.setData("text", content);
      } else {
        (function (content) {
          //oncopy 事件在用户拷贝元素上的内容时触发。
          document.oncopy = function (e) {
            e.clipboardData.setData("text", content);
            e.preventDefault();
            document.oncopy = null;
          };
        })(content);
        document.execCommand("Copy");
      }
      this.$message({
        message: this.$t('MY_CK_29'),
        type: "success",
      });
    },
    handleNodeClick() {},
    getSearch() {
      getTjFramework(this.queryParams).then((res) => {
        this.data = res.data;
      });
    },
    reChongzhi() {
      this.queryParams = {
        memberSettlePeriodId: "", //期数
        memberCode: "", //会员编号
        level: "", //代数
      };
      this.getSearch();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.tooltip {
  display: flex;
  align-content: center;
}
::v-deep .custom-tree-node .thecontent {
  padding: 10px;
  background: rgba(216, 216, 216, 0.3);
  border-radius: 8px;
  border: 1px solid #cccccc;
  margin-left: 10px;
}
// 禁止旋转
::v-deep .el-tree .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
//有子节点 且未展开
::v-deep .el-tree .el-icon-caret-right:before {
  background: url("~@/assets/images/iconadd.png") no-repeat 0 0;
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  font-size: 22px;
  background-size: 22px;
}
//有子节点 且已展开
::v-deep
  .el-tree
  .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
  background: url("~@/assets/images/iconpop.png") no-repeat 0 0;
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  font-size: 22px;
  background-size: 22px;
}
// 无子节点
::v-deep .el-tree .el-tree-node__expand-icon.is-leaf::before {
  background: none;
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  font-size: 22px;
  background-size: 22px;
}

::v-deep .el-tree-node__expand-icon {
  font-size: 32px;
  color: #1890ff;
}
::v-deep .el-tree-node__content {
  margin-bottom: 10px;
}
::v-deep .el-tree-node__content:nth-child(1) {
  margin-top: 10px;
}
::v-deep .el-tree-node__label {
  padding: 5px;
  background: rgba(216, 216, 216, 0.3);
  border-radius: 8px;
  border: 1px solid #cccccc;
  //   box-shadow: 0px 0px 12px rgba(24, 144, 255, 0.3);
}
::v-deep .el-tree-node__expand-icon.is-leaf {
  color: transparent;
  cursor: default;
}

::v-deep .el-tree-node:focus > .el-tree-node__content {
  background: rgba(0, 0, 0, 0);
}

::v-deep .el-tree-node__content:hover {
  background: rgba(0, 0, 0, 0);
}

.page {
  padding: 10px 0px;
  // background: pink;
  font-size: 14px;
  display: flex;
  overflow: auto;
  .lefttop {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-left: 20px;
    background: #ffffff;
    .single {
      img {
        width: 68px;
        height: 68px;
        border-radius: 50%;
        // box-shadow: 0px 2px 20px 0px rgba(204, 204, 204, 0.5);
      }
      .singletitle {
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
      }
    }
  }
  .thetopbox {
    padding: 10px;
    background: #ffffff;
    border-radius: 8px;
    width: 1460px;
    .searchbox {
      display: flex;
      align-items: center;
      .searchtitle {
        margin-right: 10px;
      }
      .searchbtn {
        background: #c8161d;
        color: #ffffff;
      }
    }
  }

  .tree {
    min-height: calc(80vh - 110px);
    position: relative;
    text-align: center;
    // overflow-x: scroll;
    background: #fff;
    // width: 100%;
    // margin-top: 20px;
    padding-top: 10px;
    &-content {
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
</style>