<template>
    <div class="topbox">
      <div
        class="levelList_i"
        :class="tabActive == item.path ? 'act' : ''"
        v-for="(item, index) in topList"
        :key="index"
        @click.prevent="handleLink(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </template>
  
  <script>  
  export default {
    name: "topBar",
    props: {
      topList: {
        type: Array,
      },
      moren:{
          type:String
      }
    },
    data() {
      return {
        tabActive: this.moren,
      };
    },
    methods: {
      handleLink(item) {
        this.tabActive = item.path;
          this.$router.push({
            path:item.path
          });
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .topbox {
    align-items: center;
    // height:74px;
    padding: 0px 0 10px 0;
    display: flex;
  //   background: skyblue;
    .levelList_i {
      padding: 0 15px;
      margin-left: 20px;
      position: relative;
      cursor: pointer;
      min-width: 88px;
      height: 34px;
      border-radius: 17px;
      border: 1px solid #cccccc;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      text-align: center;
      line-height: 34px;
    }
  }
  .act {
    color: #FFFFFF;
    background: #C8161D;
  
  }
  .act1 {
    border-bottom: 3px solid #1890ff !important;
  }
  .cha {
    font-size: 16px;
    color: #606266;
    position: absolute;
    top: 10px;
  }
  .li {
    width: 40px;
    margin: 0 auto;
    border-bottom: 3px solid transparent;
  }
  </style>
  