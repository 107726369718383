import request from '@/util/request'

//推荐架构1
export function getTjFramework(params) {
    return request({
        url: '/member/api/member-structure/tj-framework',
        method: 'get',
        params
    })
}

//安置架构1
export function getAzFramework(params) {
    return request({
        url: '/member/api/member-structure/az-framework',
        method: 'get',
        params
    })
}

//架构等级展示
export function getAvarerInfo(params) {
    return request({
        url: '/member/api/member-structure/get-avatar-info',
        method: 'get',
        params
    })
}

//结算期数下拉选
export function getMemberSettlePeriod(params) {
    return request({
        url: '/member/api/member-structure/get-member-settle-period',
        method: 'get',
        params
    })
}

//转换为base64格式用于图片下载
export function getUrlBase(data) {
    return request({
      url: '/member/manage/member-structure/get-url-base64',
      method: 'post',
      data
    })
  }
